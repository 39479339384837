import { useToggleSwitch } from '@storis/app_common.hooks';
import { IconButton, InputAdornment } from '@storis/app_common.ui/components';
import { VisibilityIcon, VisibilityOffIcon } from '@storis/app_common.ui/icons';
import type { FieldValues } from 'react-hook-form';
import type { ControlledTextFieldProps } from '../ControlledTextField';
import { ControlledTextField } from '../ControlledTextField';

interface RestrictedTextFieldAdornmentProps {
	isHidden: boolean;
	onClick: () => void;
	disabled?: boolean;
}

const RestrictedTextFieldAdornment = (props: RestrictedTextFieldAdornmentProps) => {
	const { isHidden, onClick, disabled } = props;

	return (
		<InputAdornment position="end">
			<IconButton aria-label="toggle visibility" onClick={onClick} disabled={disabled} edge="end">
				{isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
			</IconButton>
		</InputAdornment>
	);
};

export type ControlledRestrictedTextFieldProps<TFieldValues extends FieldValues> = Omit<
	ControlledTextFieldProps<TFieldValues>,
	'endAdornment' | 'label'
> & {
	label?: string;
	/** Defaults to 'Sensitive Data', pass 'null' to hide the helper text */
	helperText?: string | null;
};

/**
 * A specialized text field component for handling sensitive data with React Hook Form integration.
 *
 * ### When to Use
 *
 * - For password fields, API keys, or any sensitive information that should be hidden by default
 * - When users need the ability to toggle visibility of sensitive information
 *
 * ### Key Features
 *
 * - Hides sensitive data by default using the 'password' input type
 * - Toggles visibility of the input text with an end adornment icon button
 * - Built on top of the ControlledTextField component, inheriting all its functionality
 *
 * > Note: For general text inputs without visibility controls, use ControlledTextField instead.
 */
const ControlledRestrictedTextField = <TFieldValues extends FieldValues>(
	props: ControlledRestrictedTextFieldProps<TFieldValues>,
) => {
	const {
		label,
		name,
		control,
		helperText = 'Sensitive Data',
		disabled,
		InputProps,
		slotProps: slotPropsProp,
		...other
	} = props;

	const [isRestrictedFieldHidden, handlers] = useToggleSwitch(true);

	const type = isRestrictedFieldHidden ? 'password' : 'text';

	return (
		<ControlledTextField
			name={name}
			label={label}
			control={control}
			disabled={disabled}
			type={type}
			slotProps={{
				...slotPropsProp,
				input: {
					...slotPropsProp?.input,
					endAdornment: (
						<RestrictedTextFieldAdornment
							isHidden={isRestrictedFieldHidden}
							onClick={handlers.toggle}
							disabled={disabled}
						/>
					),
				},
			}}
			helperText={helperText}
			{...other}
		/>
	);
};

export default ControlledRestrictedTextField;
